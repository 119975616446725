<template>
  <div class="body">
    <section class="banner">
      <picture>
        <source
          srcset="https://alihtml.juzhen.cn/juzhen/assets/img/cs00/m/brand.png"
          media="all and (max-width: 500px)"
        />
        <img class="brand"
          src="https://alihtml.juzhen.cn/juzhen/assets/img/cs00/brand.png"
          width="253" height="67"
          alt="橙仕00"
        />
      </picture>
      
      <picture>
        <source
          srcset="https://alihtml.juzhen.cn/juzhen/assets/img/cs00/m/cs00.png"
          media="all and (max-width: 500px)"
        />
        <img class="preview"
          src="https://alihtml.juzhen.cn/juzhen/assets/img/cs00/cs00.png"
          width="555" height="304"
          alt="banner"
        />
      </picture>

      <h2><strong>橙仕配送</strong> 初心之作</h2>
      <p><strong>安全</strong>、<strong>高效</strong>、<strong>智能</strong>末端配送解决方案</p>
      <span class="btn">即将上线</span>
    </section>

    <section class="view"></section>
  </div>
</template>

<script>
export default {
  name: "cs00",
};
</script>

<style lang="scss" scoped>
.banner {
  display: flex;
  height: 1080px;
  padding: 120px 0;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  font-size: 30px;
  line-height: 40px;
  color: #fff;
  background-color: #e83e0b;

  img {
    display: block;
    max-width: 100%;
    height: auto;
  }

  h2 {
    font-size: 60px;
    line-height: 70px;
    color: #fff;
  }

  .preview {
    margin: 40px 0;
  }

  .btn {
    margin-top: 40px;
    padding: 0 130px;
    font-size: 34px;
    line-height: 70px;
    border: 2px solid #fff;
    border-radius: 70px;
  }
}

.view {
  height: 840px;
  background: #fff url(https://alihtml.juzhen.cn/juzhen/assets/img/cs00/view.png) repeat-x 50%;
  background-size: 1920px 840px;
}

@media only screen and (max-width: 500px) {
  .banner {
    padding: 114px 0;
    height: 640px;
    font-size: 16px;
    line-height: 22px;

    .brand {
      width: 111px;
    }

    .preview {
      margin: 36px 0 20px;
      width: 243px;
    }

    h2 {
      font-size: 32px;
      line-height: 38px;
    }

    .btn {
      margin-top: 34px;
      padding: 0 68px;
      font-size: 18px;
      line-height: 38px;
      border-width: 1px;
      border-radius: 40px;
    }
  }

  .view {
    height: 160px;
    background: #42424a url(https://alihtml.juzhen.cn/juzhen/assets/img/cs00/m/view.png) repeat-x 50%;
    background-size: 375px 160px;
  }

}
</style>